import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import logo from "../assets/logo.png";
import {navbar} from "../Static";

export default function NavBar() {
	const url = window.location.origin;

	const [menubar, setmenubar] = useState(false);
	const [navbarstate, setnavbarstate] = useState(false);

	useEffect(() => {
		const updatePosition = () => {
			setnavbarstate(window.pageYOffset);
		};
		window.addEventListener("scroll", updatePosition);
		updatePosition();
		return () => window.removeEventListener("scroll", updatePosition);
	}, [navbarstate]);

	const scrollToElement = id => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({behavior: "smooth"});
		}
	};
	return (
		<nav
			class="navbar navbar-expand-lg position-fixed top-0 w-100 navbar-light bg-light b-shadow"
			style={{zIndex: "9"}}
		>
			<div class="container p-lg-2 p-3 px-lg-3 px-4">
				<Link className="navbar-brand py-2" to={url}>
					<img src={logo} alt={""} />
				</Link>
				<button
					class="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
				>
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
					<ul className="justify-content-end navbar-nav">
						{navbar.map((nav, i) => (
							<li className="nav-item">
								<a
									key={i + nav.id}
									className="nav-link text-uppercase"
									href={url + "/" + `#section` + nav.id}
								>
									{nav.title}
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</nav>
	);
}

// <nav
// 	className="navbar navbar-expand-lg position-fixed top-0 w-100 navbar-light bg-light b-shadow "
// 	style={{zIndex: "9"}}
// >
// 	<div className="col-lg-10 col-11 mx-auto p-0 d-flex align-items-center justify-content-between">
// 		<Link className="navbar-brand py-2" to={url}>
// 			<img src={logo} alt={""} />
// 		</Link>
// 		<button
// 			className="btn p-0 fs-3 text-white border-0 hidden-lg hidden-md"
// 			type="button"
// 			onClick={e => setmenubar(true)}
// 		>
// 			<span className="navbar-toggler-icon"></span>
// 		</button>
// 		<div className={`collapse navbar-collapse ${menubar ? "show" : ""}`}>
// 			<ul className="justify-content-end navbar-nav">
// 				{navbar.map((nav, i) => (
// 					<li className="nav-item">
// 						<a
// 							key={i + nav.id}
// 							className="nav-link text-uppercase"
// 							href={url + "/" + `#section` + nav.id}
// 						>
// 							{nav.title}
// 						</a>
// 					</li>
// 				))}
// 			</ul>
// 		</div>
// 	</div>
// </nav>
