export const coverage = [
	{
		id: 1,
		title: "Արագածոտն",
		locations: [
			{
				id: 1,
				title: "Օշական",
				fiber: true,
				radio: false,
				latitude: 40.2734,
				longitude: 44.3592,
			},
			{
				id: 2,
				title: "Ոսկեվազ",
				fiber: true,
				radio: false,
				latitude: 40.3128,
				longitude: 44.3561,
			},
		],
	},
	{
		id: 2,
		title: "Արմավիր",
		locations: [
			{
				id: 1,
				title: "Ալաշկերտ",
				fiber: true,
				radio: false,
				latitude: 40.1714,
				longitude: 43.9952,
			},
			{
				id: 2,
				title: "Ակնաշեն",
				fiber: true,
				radio: false,
				latitude: 40.1675,
				longitude: 44.0292,
			},
			{
				id: 3,
				title: "Այգեշատ",
				fiber: true,
				radio: true,
				latitude: 40.1936,
				longitude: 44.0389,
			},
			{
				id: 4,
				title: "Այգեվան",
				fiber: true,
				radio: true,
				latitude: 40.0861,
				longitude: 44.2625,
			},
			{
				id: 5,
				title: "Ապագա",
				fiber: true,
				radio: true,
				latitude: 40.1217,
				longitude: 44.3778,
			},
			{
				id: 6,
				title: "Արազափ",
				fiber: true,
				radio: true,
				latitude: 40.1581,
				longitude: 44.0267,
			},
			{
				id: 7,
				title: "Արաքս Էջմ.",
				fiber: true,
				radio: false,
				latitude: 40.1397,
				longitude: 44.1047,
			},
			{
				id: 8,
				title: "Արաքս",
				fiber: true,
				radio: false,
				latitude: 40.0633,
				longitude: 44.3878,
			},
			{
				id: 9,
				title: "Արգավանդ",
				fiber: true,
				radio: false,
				latitude: 40.1617,
				longitude: 44.4253,
			},
			{
				id: 10,
				title: "Արևիկ",
				fiber: true,
				radio: true,
				latitude: 40.1333,
				longitude: 44.0056,
			},
			{
				id: 11,
				title: "Արմավիր",
				fiber: true,
				radio: true,
				latitude: 40.1522,
				longitude: 44.0303,
			},
			{
				id: 12,
				title: "Բաղրամյան",
				fiber: true,
				radio: true,
				latitude: 40.2506,
				longitude: 44.3192,
			},
			{
				id: 13,
				title: "Բերքաշատ",
				fiber: true,
				radio: false,
				latitude: 40.1808,
				longitude: 43.9903,
			},
			{
				id: 14,
				title: "Գայ",
				fiber: true,
				radio: true,
				latitude: 40.1128,
				longitude: 44.0953,
			},
			{
				id: 15,
				title: "Գեղակերտ",
				fiber: true,
				radio: false,
				latitude: 40.1842,
				longitude: 44.1139,
			},
			{
				id: 16,
				title: "Գետաշեն",
				fiber: true,
				radio: false,
				latitude: 40.1797,
				longitude: 44.0567,
			},
			{
				id: 17,
				title: "Դալարիկ",
				fiber: true,
				radio: false,
				latitude: 40.1742,
				longitude: 43.9047,
			},
			{
				id: 18,
				title: "Եղեգնուտ",
				fiber: true,
				radio: true,
				latitude: 40.2075,
				longitude: 44.0297,
			},
			{
				id: 19,
				title: "Զարթոնք",
				fiber: true,
				radio: true,
				latitude: 40.0878,
				longitude: 44.1881,
			},
			{
				id: 20,
				title: "Լեռնագոգ",
				fiber: true,
				radio: false,
				latitude: 40.2261,
				longitude: 44.0744,
			},
			{
				id: 21,
				title: "Լուկաշին",
				fiber: true,
				radio: true,
				latitude: 40.0978,
				longitude: 44.0022,
			},
			{
				id: 22,
				title: "Լուսագյուղ",
				fiber: true,
				radio: false,
				latitude: 40.1747,
				longitude: 44.2114,
			},
			{
				id: 23,
				title: "Խանջյան",
				fiber: true,
				radio: true,
				latitude: 40.2011,
				longitude: 44.3028,
			},
			{
				id: 24,
				title: "Հայկավան",
				fiber: true,
				radio: true,
				latitude: 40.1075,
				longitude: 44.1828,
			},
			{
				id: 25,
				title: "Հայկաշեն",
				fiber: true,
				radio: true,
				latitude: 40.1603,
				longitude: 44.0072,
			},
			{
				id: 26,
				title: "Հայ Թաղ",
				fiber: true,
				radio: false,
				latitude: 40.1308,
				longitude: 44.1425,
			},
			{
				id: 27,
				title: "Հացիկ",
				fiber: true,
				radio: true,
				latitude: 40.1836,
				longitude: 44.0106,
			},
			{
				id: 28,
				title: "Հուշակերտ",
				fiber: true,
				radio: false,
				latitude: 40.1544,
				longitude: 43.9831,
			},
			{
				id: 29,
				title: "Մարգարա",
				fiber: true,
				radio: true,
				latitude: 40.0733,
				longitude: 44.0142,
			},
			{
				id: 30,
				title: "Մեծամոր",
				fiber: true,
				radio: true,
				latitude: 40.0694,
				longitude: 44.2706,
			},
			{
				id: 31,
				title: "Մյասնիկյան",
				fiber: true,
				radio: true,
				latitude: 40.1792,
				longitude: 44.2844,
			},
			{
				id: 32,
				title: "Մրգաշատ",
				fiber: true,
				radio: false,
				latitude: 40.0761,
				longitude: 44.1625,
			},
			{
				id: 33,
				title: "Նալբանդյան",
				fiber: true,
				radio: true,
				latitude: 40.1783,
				longitude: 43.9733,
			},
			{
				id: 34,
				title: "Նոր Ամասիա",
				fiber: true,
				radio: true,
				latitude: 40.0953,
				longitude: 44.0989,
			},
			{
				id: 35,
				title: "Նոր Արմավիր",
				fiber: true,
				radio: true,
				latitude: 40.1247,
				longitude: 44.0736,
			},
			{
				id: 36,
				title: "Նոր Արտագերս",
				fiber: true,
				radio: true,
				latitude: 40.0928,
				longitude: 44.0031,
			},
			{
				id: 37,
				title: "Նոր Կեսարյա",
				fiber: true,
				radio: true,
				latitude: 40.1042,
				longitude: 44.0786,
			},
			{
				id: 38,
				title: "Շենավան",
				fiber: true,
				radio: true,
				latitude: 40.2086,
				longitude: 44.0094,
			},
			{
				id: 39,
				title: "Ջանֆիդա",
				fiber: true,
				radio: false,
				latitude: 40.1172,
				longitude: 44.1258,
			},
			{
				id: 40,
				title: "Ջրաշեն",
				fiber: true,
				radio: true,
				latitude: 40.145,
				longitude: 44.0142,
			},
			{
				id: 41,
				title: "Ջրառատ",
				fiber: true,
				radio: true,
				latitude: 40.1747,
				longitude: 44.2042,
			},
			{
				id: 42,
				title: "Վարդանաշեն",
				fiber: true,
				radio: true,
				latitude: 40.1253,
				longitude: 44.1736,
			},
			{
				id: 43,
				title: "Տանձուտ",
				fiber: true,
				radio: false,
				latitude: 40.1511,
				longitude: 43.9619,
			},
			{
				id: 44,
				title: "Տարոնիկ",
				fiber: true,
				radio: false,
				latitude: 40.1103,
				longitude: 44.1117,
			},
			{
				id: 45,
				title: "Քարակերտ",
				fiber: true,
				radio: false,
				latitude: 40.2572,
				longitude: 44.0633,
			},
			{
				id: 46,
				title: "Ֆերիկ",
				fiber: true,
				radio: false,
				latitude: 40.2219,
				longitude: 44.0222,
			},
			{
				id: 47,
				title: "Շենիկ",
				fiber: true,
				radio: true,
				latitude: 40.0728,
				longitude: 44.0278,
			},
		],
	},
	{
		id: 3,
		title: "Արարատ",
		locations: [
			{
				id: 1,
				title: "Հայանիստ",
				fiber: true,
				radio: false,
				latitude: 39.9153,
				longitude: 44.5453,
			},
			{
				id: 2,
				title: "Ղուկասավան",
				fiber: true,
				radio: true,
				latitude: 39.9042,
				longitude: 44.5436,
			},
			{
				id: 3,
				title: "Մրգավետ",
				fiber: true,
				radio: true,
				latitude: 39.9,
				longitude: 44.5586,
			},
			{
				id: 4,
				title: "Դալար",
				fiber: true,
				radio: true,
				latitude: 39.8756,
				longitude: 44.5506,
			},
			{
				id: 5,
				title: "Ոսկետափ",
				fiber: true,
				radio: true,
				latitude: 39.8856,
				longitude: 44.5292,
			},
			{
				id: 6,
				title: "Նորամարգ",
				fiber: true,
				radio: false,
				latitude: 39.9242,
				longitude: 44.5203,
			},
			{
				id: 7,
				title: "Նոր Կյուրին",
				fiber: true,
				radio: true,
				latitude: 39.9239,
				longitude: 44.5353,
			},
			{
				id: 8,
				title: "Հովտաշեն",
				fiber: true,
				radio: false,
				latitude: 39.9331,
				longitude: 44.5472,
			},
			{
				id: 9,
				title: "Նորաշեն",
				fiber: true,
				radio: false,
				latitude: 39.9311,
				longitude: 44.5544,
			},
			{
				id: 10,
				title: "Քաղցրաշեն",
				fiber: true,
				radio: false,
				latitude: 39.9211,
				longitude: 44.5211,
			},
			{
				id: 11,
				title: "Տափերական",
				fiber: true,
				radio: false,
				latitude: 39.9058,
				longitude: 44.5169,
			},
			{
				id: 12,
				title: "Դիմիտրով",
				fiber: true,
				radio: true,
				latitude: 39.8906,
				longitude: 44.5092,
			},
			{
				id: 13,
				title: "Փոքր Վեդի",
				fiber: true,
				radio: false,
				latitude: 39.8858,
				longitude: 44.5289,
			},
			{
				id: 14,
				title: "Այգավան",
				fiber: true,
				radio: true,
				latitude: 39.8772,
				longitude: 44.5378,
			},
			{
				id: 15,
				title: "Այգեզարդ",
				fiber: true,
				radio: false,
				latitude: 39.8711,
				longitude: 44.5239,
			},
			{
				id: 16,
				title: "Մրգավան",
				fiber: true,
				radio: false,
				latitude: 39.8653,
				longitude: 44.5086,
			},
			{
				id: 17,
				title: "Բաղրամյան",
				fiber: true,
				radio: true,
				latitude: 39.9108,
				longitude: 44.5569,
			},
			{
				id: 18,
				title: "Այգեստան",
				fiber: true,
				radio: false,
				latitude: 39.8864,
				longitude: 44.52,
			},
			{
				id: 19,
				title: "Բյուրավան",
				fiber: true,
				radio: false,
				latitude: 39.8969,
				longitude: 44.5219,
			},
			{
				id: 20,
				title: "Դեղձուտ",
				fiber: true,
				radio: false,
				latitude: 39.8775,
				longitude: 44.5114,
			},
			{
				id: 21,
				title: "Գետազատ",
				fiber: true,
				radio: false,
				latitude: 39.8617,
				longitude: 44.5164,
			},
			{
				id: 22,
				title: "Լանջազատ",
				fiber: true,
				radio: false,
				latitude: 39.8542,
				longitude: 44.5097,
			},
			{
				id: 23,
				title: "Ազատավան",
				fiber: true,
				radio: false,
				latitude: 39.8417,
				longitude: 44.5044,
			},
			{
				id: 24,
				title: "Արևշատ",
				fiber: true,
				radio: false,
				latitude: 39.8328,
				longitude: 44.4908,
			},
			{
				id: 25,
				title: "Դեղձուտ",
				fiber: true,
				radio: false,
				latitude: 39.8775,
				longitude: 44.5114,
			},
			{
				id: 26,
				title: "Նշավան",
				fiber: true,
				radio: true,
				latitude: 39.8786,
				longitude: 44.4947,
			},
			{
				id: 27,
				title: "Վարդաշեն",
				fiber: true,
				radio: false,
				latitude: 39.8825,
				longitude: 44.4839,
			},
			{
				id: 28,
				title: "Դիտակ",
				fiber: true,
				radio: false,
				latitude: 39.8778,
				longitude: 44.4719,
			},
			{
				id: 29,
				title: "Նոր Խարբերդ",
				fiber: true,
				radio: false,
				latitude: 39.8664,
				longitude: 44.4697,
			},
			{
				id: 30,
				title: "Մարմարաշեն",
				fiber: true,
				radio: true,
				latitude: 39.8542,
				longitude: 44.4558,
			},
			{
				id: 31,
				title: "Մրգավետ",
				fiber: true,
				radio: true,
				latitude: 39.9,
				longitude: 44.5586,
			},
			{
				id: 32,
				title: "Մխչյան",
				fiber: true,
				radio: true,
				latitude: 39.9314,
				longitude: 44.6111,
			},
			{
				id: 33,
				title: "Ջրաշեն",
				fiber: true,
				radio: true,
			},
			{
				id: 34,
				title: "Ջրահովիտ",
				fiber: true,
				radio: false,
			},
			{
				id: 35,
				title: "Դաշտավան",
				fiber: true,
				radio: true,
			},
			{
				id: 36,
				title: "Զորակ",
				fiber: true,
				radio: true,
			},
			{
				id: 37,
				title: "Դարբնիկ",
				fiber: true,
				radio: false,
			},
			{
				id: 38,
				title: "Սայաթ-Նովա",
				fiber: true,
				radio: false,
			},
			{
				id: 39,
				title: "Հովտաշատ",
				fiber: true,
				radio: false,
			},
			{
				id: 40,
				title: "Արբաթ",
				fiber: true,
				radio: true,
			},
			{
				id: 41,
				title: "Գեղանիստ",
				fiber: true,
				radio: true,
			},
			{
				id: 42,
				title: "Դարակերտ",
				fiber: true,
				radio: true,
			},
			{
				id: 43,
				title: "Ղուկասավան",
				fiber: true,
				radio: true,
			},
		],
	},
	{
		id: 4,
		title: "Գեղարքունիք",
		locations: [
			{
				id: 1,
				title: "Գանձակ ",
				fiber: true,
				radio: false,
			},
			{
				id: 2,
				title: "Գեղարքունիք ",
				fiber: true,
				radio: false,
			},
			{
				id: 3,
				title: "Գոմաձոր ",
				fiber: true,
				radio: false,
			},
			{
				id: 4,
				title: "Լանջաղբյուր ",
				fiber: true,
				radio: false,
			},
			{
				id: 5,
				title: "Լճաշեն ",
				fiber: true,
				radio: true,
			},
			{
				id: 6,
				title: "Կարմիրգյուղ ",
				fiber: true,
				radio: false,
			},
			{
				id: 7,
				title: "Չկալովկա ",
				fiber: true,
				radio: false,
			},
			{
				id: 8,
				title: "Սարուխան ",
				fiber: true,
				radio: false,
			},
			{
				id: 9,
				title: "ք. Սևան ",
				fiber: true,
				radio: false,
			},
			{
				id: 10,
				title: "Վարսեր",
				fiber: true,
				radio: false,
			},
		],
	},
	{
		id: 5,
		title: "Կոտայք",
		locations: [
			{
				id: 12,
				title: "Գառնի ",
				fiber: true,
				radio: true,
			},
			{
				id: 13,
				title: "Զովունի ",
				fiber: true,
				radio: true,
			},
			{
				id: 14,
				title: "Ներքին Պտղնի ",
				fiber: true,
				radio: false,
			},
			{
				id: 15,
				title: "Պռոշյան ",
				fiber: true,
				radio: false,
			},
			{
				id: 16,
				title: "Արզնի ",
				fiber: true,
				radio: false,
			},
			{
				id: 17,
				title: "Բալահովիտ ",
				fiber: true,
				radio: false,
			},
			{
				id: 18,
				title: "Գեղադիր ",
				fiber: true,
				radio: false,
			},
			{
				id: 19,
				title: "Գողթ ",
				fiber: true,
				radio: true,
			},
			{
				id: 20,
				title: "Հացավան ",
				fiber: true,
				radio: false,
			},
			{
				id: 21,
				title: "Ողջաբերդ ",
				fiber: true,
				radio: false,
			},
			{
				id: 22,
				title: "Ձորաղբյուր",
				fiber: true,
				radio: false,
			},
		],
	},
	{
		id: 6,
		title: "Շիրակ",
		locations: [
			{
				id: 24,
				title: "Ախուրյան",
				fiber: true,
				radio: false,
			},
			{
				id: 25,
				title: "Հացիկ",
				fiber: true,
				radio: false,
			},
			{
				id: 26,
				title: "Հովունի",
				fiber: true,
				radio: false,
			},
			{
				id: 27,
				title: "Գյումրի",
				fiber: true,
				radio: false,
			},
			{
				id: 28,
				title: "Շիրակ",
				fiber: true,
				radio: false,
			},
			{
				id: 29,
				title: "Ջաջուռ",
				fiber: true,
				radio: false,
			},
			{
				id: 30,
				title: "Քեթի",
				fiber: true,
				radio: false,
			},
			{
				id: 31,
				title: "Մեծ Սարիար",
				fiber: true,
				radio: false,
			},
		],
	},
	{
		id: 7,
		title: "Տավուշ",
		locations: [
			{
				id: 33,
				title: "Գետահովիտ",
				fiber: true,
				radio: false,
			},
			{
				id: 34,
				title: "Ենոքավան",
				fiber: true,
				radio: false,
			},
			{
				id: 35,
				title: "Իջևան",
				fiber: true,
				radio: false,
			},
			{
				id: 36,
				title: "Լուսաձոր",
				fiber: true,
				radio: false,
			},
			{
				id: 37,
				title: "Լուսահովիտ",
				fiber: true,
				radio: false,
			},
			{
				id: 38,
				title: "Սևքար",
				fiber: true,
				radio: false,
			},
			{
				id: 39,
				title: "Ակնաղբյուր",
				fiber: true,
				radio: false,
			},
			{
				id: 40,
				title: "Աչաջուր",
				fiber: true,
				radio: false,
			},
			{
				id: 41,
				title: "Սարիգյուղ",
				fiber: true,
				radio: false,
				latitude: 40.9983,
				longitude: 45.1033,
			},
			{
				id: 42,
				title: "Այգեհովիտ",
				fiber: true,
				radio: false,
				latitude: 41.0,
				longitude: 45.15,
			},
			{
				id: 43,
				title: "Խաշթառակ",
				fiber: true,
				radio: false,
				latitude: 40.995,
				longitude: 45.117,
			},
		],
	},
];
