import React, { useEffect} from 'react'
import { Routes, Route } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import LandingPage from './components/LandingPage';
import Reports from './components/Reports';
import Terms from './components/Terms';

const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export default function App() {
  useScrollToTop();

  return (
    <div className="overflow-hidden">
      <NavBar />
      <div className="content-wrapper container-fluid p-0">
           <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
      </div>
      <Footer />
    </div>
  );
}
