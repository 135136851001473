import React, {useEffect, useState} from "react";
import {
	YMaps,
	Map,
	Placemark,
	ZoomControl,
	FullscreenControl,
	Clusterer,
} from "@pbe/react-yandex-maps";
import {coverage} from "../Coverage"; // Import your coverage JSON here

export default function MapPoints() {
	const [state, setState] = useState({
		selectedPoint: null,
		position: [],
	});

	useEffect(() => {
		// Extract locations with latitude and longitude from the coverage JSON
		const provinces = coverage.flatMap(region =>
			(region.locations || []).filter(location => location.latitude && location.longitude)
		);
		setState({position: provinces});
	}, []);

	// Render placemarks based on the processed locations
	const locations = state.position.map((position, index) => (
		<Placemark
			key={index}
			geometry={[position.latitude, position.longitude]}
			modules={["geoObject.addon.balloon"]}
			properties={{
				balloonContentBody: `
					<strong>Location:</strong> ${position.title}<br/>
					<strong>Fiber:</strong> <span style="color: ${position.fiber ? "green" : "red"}">
						${position.fiber ? "Available" : "Not Available"}
					</span><br/>
					<strong>Radio:</strong> <span style="color: ${position.radio ? "green" : "red"}">
						${position.radio ? "Available" : "Not Available"}
					</span>
				`,
			}}
			options={{
				hideIconOnBalloonOpen: false,
				balloonCloseButton: true,
				balloonOffset: [0, -17],
				preset: "islands#redCircleDotIcon", // Red icon for placemarks
			}}
		/>
	));

	return (
		<div className="col-12 p-0 b-radius b-shadow">
			<YMaps>
				<Map
					width="100%"
					height="450px"
					defaultState={{
						center: [40.4226651, 44.3097432],
						zoom: 9,
						type: "yandex#map",
						controls: [],
					}}
					options={{suppressMapOpenBlock: true}}
					instanceRef={ref => {
						if (ref) {
							ref.events.add("click", () => {
								ref.balloon.close();
							});
						}
					}}
				>
					<ZoomControl options={{float: "right"}} />
					<FullscreenControl />
					<Clusterer
						options={{
							preset: "islands#invertedRedClusterIcons", // Red cluster icon
							groupByCoordinates: false,
						}}
					>
						{locations}
					</Clusterer>
				</Map>
			</YMaps>
		</div>
	);
}
