import React from "react";
import LottieAnimator from "./ui-elements/LottieAnimator";

export default function HeroBanner(props) {
	return (
		<section
			className="d-flex align-items-end"
			style={
				{
					// minHeight: "650px",
				}
			}
		>
			<div className="container-fluid px-xl-5 pt-5">
				<div className="row justify-content-end p-0 m-0 ">
					<div className="col-xl-5 col-lg-6 col-12 mt-xl-5 mt-lg-0 mt-5 pt-xl-5 pt-lg-0">
						<div className="text-lg-end text-center ">
							<h2 className="display-4 fw-bolder dark-green-bg gradient-text">ԳԵՐԱՐԱԳ</h2>
							<h2 className="display-4 fw-bolder dark-green-bg gradient-text">ԻՆՏԵՐՆԵՏ ԿԱՊ</h2>
							<h2 className="display-4 fw-bolder dark-green-bg gradient-text mt-3">ԹՎԱՅԻՆ</h2>
							<h2 className="display-4 fw-bolder dark-green-bg gradient-text m-0">
								ՀԵՌՈՒՍՏԱԱԼԻՔՆԵՐ
							</h2>
						</div>
						<div className="text-lg-end text-center mt-5 mb-5 mb-lg-0">
							<span className="btn dark-green-bg gradient text-white b-radius b-shdow px-4 py-2">
								Բացահայտե'ք առաջարկները
							</span>
						</div>
					</div>
					<div className="col-xl-7 col-lg-6 col-md-10 mx-auto d-flex justify-content-end align-item-end">
						<LottieAnimator lottie={props.lottie} />
					</div>
				</div>
			</div>
		</section>
	);
}
