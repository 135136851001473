import {Accordion} from "react-bootstrap";
import {documents} from "../Documents";

export default function Reports() {
	const reportDocuments = documents.filter(doc => doc.reports === true);
	return (
		<div className="container my-5">
			<section className="py-5 my-5">
				<div className="my-5">
					<h1 className="dark-green-bg gradient-text text-center fs-1 fw-bolder text-uppercase mb-5">
						Հաշվետվություններ
					</h1>
					<Accordion defaultActiveKey="0">
						{reportDocuments.map((doc, index) => (
							<Accordion.Item
								eventKey={index.toString()}
								key={index}
								className="my-3 b-radius b-shadow border-0 px-3 py-1"
							>
								<Accordion.Header>{doc.title}</Accordion.Header>
								<Accordion.Body>
									{doc.documents.map((file, fileIndex) => (
										<div key={fileIndex} className="mb-2">
											<a
												href={file.file}
												target="_blank"
												rel="noopener noreferrer"
												className="text-decoration-none"
											>
												{file.title}
											</a>
										</div>
									))}
								</Accordion.Body>
							</Accordion.Item>
						))}
					</Accordion>
				</div>
			</section>
		</div>
	);
}
