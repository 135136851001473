import React, {useState} from "react";
import {FiChevronRight} from "react-icons/fi";
import {FaInstagram} from "react-icons/fa6";
import {FaFacebook} from "react-icons/fa";
import footer_bg from "../assets/images/footer-bg.png";
import {Link} from "react-router-dom";

export default function Footer() {
	const [modalContent, setModalContent] = useState("");
	const [isModalOpen, setModalOpen] = useState(false);

	const openModal = content => {
		setModalContent(content);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	return (
		<footer className="col-12 p-0" style={{backgroundImage: `url(${footer_bg})`}}>
			<div className="col-lg-10 col-12 mx-auto p-0 mt-5 text-center text-lg-start">
				<div className="row justify-content-center align-items-center">
					<div className="col-lg-4 col-12">
						<div className="d-block my-3">
							Իրավաբանական հասցե`
							<br /> ՀՀ ք. Վաղարշապատ, Շահումյան 39
						</div>
						<div className="d-block my-3">
							Զանգերի սպասարկան կենտրոն` <br />
							091 19 52 65, 094 19 52 65, 055 19 52 65
						</div>
						<div className="d-block my-3">
							Առաջարկների և/կամ բողոքների համար կարող եք գրել մեզ <br />
							<a href="" className="text-white text-decoration-none">
								info@realtime.am
							</a>
						</div>
						<div className="d-block my-3">
							Եթե ցանկանում ե՞ք միանալ մեր թիմին
							<br />
							<a href="" className="text-white text-decoration-none">
								hr@realtime.am
							</a>
						</div>
						<div className="d-block my-3">
							<a
								target="blank"
								className="text-white text-decoration-none"
								href="https://www.facebook.com/realtime.internet"
							>
								<FaFacebook className="fs-3 me-2" />
							</a>
							<a
								target="blank"
								className="text-white text-decoration-none"
								href="https://www.instagram.com/realtime.am"
							>
								<FaInstagram className="fs-3 me-2" />
							</a>
						</div>
					</div>
					<div className="col-lg-4 col-12 links text-uppercase">
						<div className="w-100 my-4">
							<Link to={"/reports"} className="col-lg-4 col-12 text-white">
								<FiChevronRight className="me-2" />
								Հաշվետվություններ
							</Link>
						</div>
						<div className="w-100 my-4">
							<Link to={"/terms"} className="col-lg-4 col-12 text-white">
								<FiChevronRight className="me-2" />
								Պայմաններ եվ դրույթներ
							</Link>
						</div>
					</div>
					<div className="col-lg-4 col-12">
						<div className="row">
							<div className="w-100 my-3">
								<p className="mb-1">© «Ռեալթայմ» ՍՊԸ 2001 - {new Date().getFullYear()} </p>
								<p className="mb-0">Բոլոր իրավունքները պաշտպանված են:</p>
							</div>
							<div className="w-100 my-3">
								<p className="mb-1">Կայքը մշակվել և ձևավորվել է </p>
								<p className="mb-0">«Ռեալթայմ» ՍՊ ընկերության կողմից:</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
