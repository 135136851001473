import IPTV from "./IPTV";
import FiberInternet from "./FiberInternet";
import RadioInternet from "./RadioInternet";
import CoverageMap from "./CoverageMap";

import {tvandinternet, fiberinternet, radiointernet, specials, phoneNumbers} from "../Static";
import Specials from "./ui-elements/Specials";
import Contacts from "./Contacts";
import About from "./About";
import CoverageAcardion from "./CoverageAcardion";
import HeroBanner from "./HeroBanner";

import virtualreality_animation from "../assets/animation/virtualreality-banner.json";
import virtualreality_anim_02_animation from "../assets/animation/virtualreality-anim-02.json";
import internet_banner from "../assets/animation/internet-banner.json";
import cyber_security_anim_03 from "../assets/animation/cyber-security-anim-03.json";
import LottieAnimator from "./ui-elements/LottieAnimator";

export default function LandingPage() {
	return (
		<div className="mt-5">
			<section className="p-0 mt-5 ">
				<HeroBanner lottie={virtualreality_animation} />
			</section>
			<section id="section1" className="container my-5">
				<div className="col-12">
					<div className="dark-green-bg gradient-text text-center fs-1 fw-bolder text-uppercase py-4">
						Թվային հեռուստաալիքներ եվ Ինտերնետ կապ
					</div>

					<IPTV data={tvandinternet} />
				</div>
			</section>

			<section id="section2" className="container  my-5">
				<div className="col-12">
					<div className="dark-green-bg gradient-text text-center fs-1 fw-bolder text-uppercase py-4">
						Ինտերնետ կապ
					</div>
					<div className="row">
						<div className="col-lg-6 col-md-8 mx-auto col-12 pe-xl-3">
							<div className="card b-radius b-shadow border-0 py-4 px-0 px-xl-3 px-lg-0 m-2">
								<div className="dark-green-bg gradient-text text-center fs-4 fw-bolder text-uppercase py-4">
									Օպտիկամանրատելային կապ
								</div>
								<FiberInternet data={fiberinternet} />
							</div>
						</div>
						<div className="col-lg-6 col-md-8 mx-auto col-12 ps-xl-3">
							<div className="card b-radius b-shadow border-0 py-4 px-0 px-xl-3 px-lg-0 m-2">
								<div className="dark-green-bg gradient-text text-center fs-4 fw-bolder text-uppercase py-4">
									Ռադիոմոդեմային կապ
								</div>
								<RadioInternet data={radiointernet} />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="container">
				<div className="col-lg-6 col-md-8  mx-auto">
					<div className="card w-100 b-radius b-shadow p-0 border-0">
						<div className="row justify-content-center align-items-center">
							<div className="col-sm-4 col-8 mx-auto">
								<LottieAnimator lottie={cyber_security_anim_03} />
							</div>
							<div className="col-sm-8 col-12 pe-sm-5 px-sm-0 px-5 pb-5 pb-sm-0">
								Հեռուստատեսային ծառայություններն իրականացվում և սպասարկվում են «Իքս Թիվի» ՍՊ
								ընկերության կողմից:
								<a target="blank" href="http://realtv.am/" className="ms-2 text-decoration-none">
									Անցնել կայք
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <section
				id="section3"
				className="col-12 p-0 "
				style={{
					// height: "700px",
					background: "#eff4fa",
				}}
			>
				<div className="container  my-5 position-relative">
					<div className="col-12 py-5">
						<div className="dark-green-bg gradient-text text-center fs-1 fw-bolder text-uppercase py-5">
							Հատուկ առաջարկ
						</div>

						<div className="row aling-items-center">
							<div className="col-lg-5 col-md-5 col-sm-8 col-6 mx-auto pt-0 mt-0">
								<LottieAnimator lottie={virtualreality_anim_02_animation} />
							</div>
							<div className="col-lg-7 col-md-6 col-10 mt-lg-5 mx-auto">
								{specials.map((special, i) => (
									<Specials data={special} />
								))}
							</div>
						</div>
					</div>
				</div>
			</section> */}
			<section className="container my-lg-5 my-2">
				<About />
			</section>

			<section id="section4" className="container my-5">
				<div className="dark-green-bg gradient-text text-center fs-1 fw-bolder text-uppercase py-5">
					Հասանելիության քարտեզ
				</div>
				<div className="col-12 p-0">
					<div className="row p-0 m-0">
						<div className="col-xl-7 col-lg-6 col-12">
							<div className="w-100 p-0 b-radius b-shadow border-0 overflow-hidden">
								<CoverageMap />
							</div>
						</div>
						<div className="col-xl-5 col-lg-6 col-12 mt-lg-0 mt-5">
							<div className="w-100 b-radius b-shadow border-0 overflow-hidden">
								<CoverageAcardion />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="section5" className="container mb-5">
				<div className="row align-xl-items-center p-0 m-0 ">
					<div className="col-lg-8 col-md-7 order-lg-1 order-2">
						<div className="dark-green-bg gradient-text fs-2 fw-bolder text-uppercase">
							Միացման համար զանգահարեք եվ գրանցվեք
						</div>
						<div className="text-start dark-green-bg gradient-text fs-5 fw-bolder text-uppercase mb-5">
							Մենք աշխատում ենք՝ 09:00-24:00
						</div>
						<div className="row">
							{phoneNumbers.map((number, i) => (
								<Contacts
									id={i}
									number={number.number}
									mode={number.mode}
									lnumber={number.lnumber}
								/>
							))}
						</div>
					</div>
					<div className="col-lg-3 col-md-4 col-6 mx-auto order-lg-2 order-1 p-0">
						<LottieAnimator lottie={internet_banner} />
					</div>
				</div>
			</section>
		</div>
	);
}
