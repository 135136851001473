import React from 'react'
import InternetTariffCard from './ui-elements/InternetTariffCard'

export default function FiberInternet(props) {
  return (
    <div>
      {props.data.map((tarif, i) => 
        <InternetTariffCard data={tarif}/>
      )}
    </div>
  )
}
