import React from "react";
import {Accordion, Table} from "react-bootstrap";
import {coverage} from "../Coverage";
import {RiMapPinFill} from "react-icons/ri";
import {BsCheckCircleFill, BsXCircleFill} from "react-icons/bs";

export default function CoverageAccordion() {
	return (
		<Accordion defaultActiveKey="0" className="w-100 border-0">
			{coverage.map((province, index) => (
				<Accordion.Item eventKey={String(index)} key={province.id}>
					<Accordion.Header>
						<span className="text-uppercase">{province.title}</span>
						<span className="small ms-2">
							(hասանելի է <span className="mx-2 text-danger">{province.locations.length}</span>{" "}
							բնակավայր)
						</span>
					</Accordion.Header>
					<Accordion.Body className="p-0 m-0">
						<Table striped bordered hover responsive className="m-0 p-0">
							<thead>
								<tr>
									<th>Բնակավայր</th>
									<th>Ռադիոմոդեմային</th>
									<th>Օպտիկամանրաթելային</th>
								</tr>
							</thead>
							<tbody>
								{province.locations.map((city, i) => (
									<tr key={i}>
										<td>
											<RiMapPinFill className="text-muted me-2 text-start" /> {city.title}
										</td>
										<td className="text-center">
											{city.radio ? (
												<BsCheckCircleFill className="text-success" />
											) : (
												<BsXCircleFill className="text-muted" />
											)}
										</td>
										<td className="text-center">
											{city.fiber ? (
												<BsCheckCircleFill className="text-success" />
											) : (
												<BsXCircleFill className="text-muted" />
											)}
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Accordion.Body>
				</Accordion.Item>
			))}
		</Accordion>
	);
}
