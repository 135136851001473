import {CgPhone} from "react-icons/cg";

export default function Contacts(props) {
	return (
		<div key={props.id} className="col-xl-4 col-lg-6 col-12 stretch-card tariff p-0">
			<div className="card w-100 b-radius b-shadow m-2 p-3 bg-light stretch-card">
				<div className="row justify-content-between align-items-center ">
					<a
						href={props.mode + ":" + props.lnumber}
						className="dark-green-bg gradient-text fs-3 small fw-bolder text-uppercase bebas"
					>
						<CgPhone className="me-xl-2 small fs-6 text-gray" /> {props.number}
					</a>
				</div>
			</div>
		</div>
	);
}
