import React from "react";
import IptvTartiffCard from "./ui-elements/IptvTariffCard";

export default function IPTV(props) {
	return (
		<div className="row my-4">
			{props.data.map((tarif, i) => (
				<IptvTartiffCard data={tarif} />
			))}
		</div>
	);
}
