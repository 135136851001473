import React from "react";
import {BsGlobe, BsTv, BsWifi, BsWifiOff} from "react-icons/bs";
import {FiRadio} from "react-icons/fi";

export default function IptvTariffCard(props) {
	return (
		<div key={props.data.id} className="col-lg-4 col-md-6 col-12 stretch-card tariff p-0">
			<div className="card w-100 b-radius b-shadow m-3 px-5 py-4 stretch-card">
				<div className="row justify-content-between align-items-center">
					<div className="d-flex align-items-baseline justify-content-center">
						<div className="display-4 fw-bold mb-0 bebas dark-green-bg gradient-text text-center">
							{" "}
							{props.data.price}
						</div>
						<div className="text-gray ms-1">դրամ</div>
					</div>
					<div className="fs-3 mt-3 text-uppercase text-center bebas">{props.data.title}</div>

					<div className="col-10 mx-auto border-top mt-3">
						<div className="d-flex justify-content-center align-items-center border-bottom py-3">
							<div className="d-flex align-items-center">
								<BsGlobe className="text-start h3 me-3" />
								<div className="h1 dark-green-bg gradient-text bebas">{props.data.speed}</div>
							</div>
							<div className="d-grid small ms-2 lh">
								<div>Մինչև</div>
								<div>ՄԲԻԹ/ՎՐԻ</div>
							</div>
						</div>
						<div className="d-flex justify-content-center align-items-center border-bottom py-3">
							<div className="d-flex align-items-center">
								<BsTv className="text-start h3 me-3" />
								<div className="h1 dark-green-bg gradient-text bebas">{props.data.channels}</div>
							</div>
							<div className="d-grid small ms-2 lh">
								<div>Մինչև</div>
								<div>ՄԲԻԹ/ՎՐԻ</div>
							</div>
						</div>
						<div className="d-flex justify-content-center align-items-center py-3">
							<div className="d-flex">
								<BsWifi className="text-start h3 me-3" />
							</div>
							<div className="d-grid small lh dark-green-bg gradient-text fw-bold">
								<div>Անվճար</div>
								<div>WIFI ակտիվացում</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
