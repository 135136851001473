import React from "react";
import {BsGlobe, BsWifi, BsWifiOff} from "react-icons/bs";
import {FiRadio} from "react-icons/fi";

export default function InternetTariffCard(props) {
	return (
		<div key={props.data.id} className="col-12 stretch-card tariff">
			<div className="card b-radius b-shadow  m-3 px-4 py-4 stretch-card w-100">
				<div className="row justify-content-between align-items-center">
					<div className="col-5">
						<div className="d-flex align-items-baseline">
							<div className="display-4 fw-bold mb-0 bebas dark-green-bg gradient-text text-center">
								{" "}
								{props.data.price}
							</div>
							<div className="text-gray ms-1">դրամ</div>
						</div>
						<div className="fs-4 mt-2 text-uppercase bebas">{props.data.title}</div>
					</div>
					<div className="col-7">
						<div className="d-flex align-items-center">
							<div className="d-flex align-items-center">
								<div>
									{props.data.cat === "fiber" ? (
										<BsGlobe className="me-3 h3" />
									) : (
										<FiRadio className="me-3 h3" />
									)}
								</div>
								<div className="display-4 fw-bold mb-0 bebas dark-green-bg gradient-text text-center">
									{props.data.speed}
								</div>
							</div>
							<div className="d-grid small ms-2 lh">
								<div>Մինչև</div>
								<div>ՄԲԻԹ/ՎՐԻ</div>
							</div>
						</div>
						<div className="d-flex align-items-center">
							<div className="d-flex">
								<div>
									{props.data.wifi ? (
										<BsWifi className="me-3 h3" />
									) : (
										<BsWifiOff className="me-3 h3" />
									)}
								</div>
							</div>
							<div className="d-grid small lh">
								<div>Անվճար</div>
								<div>WIFI ակտիվացում</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
