import React from "react";
import internet_anim_02 from "../assets/animation/internet-anim-02.json";
import LottieAnimator from "./ui-elements/LottieAnimator";

const settings = {
	dots: true,
	infinite: true,
	speed: 800,
	slidesToShow: 1,
	slidesToScroll: 1,
	fade: true,
	autoplay: true,
	autoplaySpeed: 3000,
	draggable: true,
};

export default function About(props) {
	return (
		<div className="row">
			<div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-8 mx-auto stretch-card order-1">
				<LottieAnimator lottie={internet_anim_02} />
			</div>
			<div
				className="col-xl-9 col-lg-8 col-md-7 col-12 stretch-card order-2"
				style={{lineHeight: "28px", fontSize: "16px"}}
			>
				<div className="p-lg-5 p-3 m-2">
					<h1 className="dark-green-bg gradient-text fs-1 fw-bolder text-uppercase py-5 text-md-start text-center">
						Մեր մասին
					</h1>
					«Ռեալ Թայմ» ՍՊ ընկերությունը հիմնադրվել է 2001 թվականին։ 2007 թվականից ընկերությունը սկսել
					է համացանցի հասանելիության ծառայության մատուցում Երևանի տարածքում, հետագայում նաև ՀՀ
					մարզային մի շարք բնակավայրերում։ Կազմակերպության առաքելությունն է ինտերնետը ՀՀ գյուղական
					համայնքներում հասանելի դարձնել բոլորին ։ Անընդհատ կատարելագործվելով՝ սպասարկման նոր
					տեխնոլոգիաների կիրառմամբ և ժամանակակից սարքավորումներ օգտագործելով, կազմակերպությունը իր
					ճանապարհն է կառուցում առաքելության իրագործման համար։ Ընկերության առաջիկա նպատակներից է նոր
					տարածքներում հասանելության կետերի կառուցումը, ինչպես նաև արդեն առկա ցանցի կատարելագործումը
					և բարելավումը։
				</div>
			</div>
		</div>
	);
}
