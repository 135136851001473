import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import App from "./App";
import 'bootstrap'
import './assets/css/bootstrap.css'
import "./assets/fonts/calibri.css";
import "./assets/css/styles.css";
const app = (
	<Suspense fallback={"Loading"}>
		<BrowserRouter>
			<Routes>
				<Route path="/*" element={<App />} />
			</Routes>
		</BrowserRouter>
	</Suspense>
);

ReactDOM.render(app, document.querySelector("#realtime"));
