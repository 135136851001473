export const alt = "Arpinet mobile tv application";

export const navbar = [
	{
		id: "1",
		title: "Հեռուստաալիքներ",
	},
	{
		id: "2",
		title: "Ինտերնետ",
	},
	{
		id: "3",
		title: "Հատուկ առաջարկ",
	},
	{
		id: "4",
		title: "Հասանելիություն",
	},
	{
		id: "5",
		title: "Կապ",
	},
];

export const footer = [
	{
		id: "1",
		title: "Ինտերնետ",
	},
	{
		id: "2",
		title: "Հեռուստաալիքներ",
	},
	{
		id: "3",
		title: "Հասանելիություն",
	},
	{
		id: "4",
		title: "Կապ",
	},
	{
		id: "5",
		title: "Փաստաթղթեր",
	},
];
// <-- տարիֆներ -->

// թվային հեռուստաալիքներ և ինտերնետ
export const tvandinternet = [
	{
		id: "1",
		cat: "iptv",
		price: "5900",
		speed: "25",
		channels: "114",
		wifi: true,
		title: "Econom + IPTV",
	},
	{
		id: "2",
		cat: "iptv",
		price: "6900",
		speed: "40",
		channels: "114",
		wifi: true,
		title: "Standart + IPTV",
	},
	{
		id: "3",
		cat: "iptv",
		price: "8900",
		speed: "60",
		channels: "114",
		wifi: true,
		title: "Ultra + IPTV",
	},
];

// օպտիկամանրաթելային ինտերնետ
export const fiberinternet = [
	{
		id: "1",
		cat: "fiber",
		price: "4900",
		speed: "25",
		wifi: true,
		title: "Econom",
	},
	{
		id: "2",
		cat: "fiber",
		price: "5900",
		speed: "40",
		wifi: true,
		title: "Standart",
	},
	{
		id: "3",
		cat: "fiber",
		price: "7900",
		speed: "60",
		wifi: true,
		title: "Ultra",
	},
];

// ռադոմեդեմային ինտերնետ
export const radiointernet = [
	{
		id: "1",
		cat: "radio",
		price: "3800",
		speed: "25",
		wifi: false,
		title: "Էկոնոմ-6",
	},
	{
		id: "2",
		cat: "radio",
		price: "5000",
		speed: "40",
		wifi: true,
		title: "Մատչելի-10",
	},
	{
		id: "3",
		cat: "radio",
		price: "7000",
		speed: "60",
		wifi: true,
		title: "Արագ-20",
	},
];

// Առաջարկներ
export const specials = [
	// // {
	// // 	id: "1",
	// // 	title: "Մեծ ընտանիք",
	// // 	description: "Ձեր փոքրիկի լույս աշխարհ գալու կապակցությամբ 1 ամիս նվեր: ",
	// // },
	// {
	// 	id: "2",
	// 	title: "Միացիր մեզ՝ դու անակնկալ ունես",
	// 	description: "Նշեք Ձեզ հրավիրող ընկերոջը և ստացեք միասին Ձեր ընտրած սակագնի չափով բոնուսներ:",
	// },
];

// Հետադարձ կապ
export const phoneNumbers = [
	{
		id: 1,
		number: "091 19 52 65",
		lnumber: "+37491195265",
		mode: "tel",
	},
	{
		id: 2,
		number: "094 19 52 65",
		lnumber: "+37494195265",
		mode: "tel",
	},
	{
		id: 3,
		number: "055 19 52 65",
		lnumber: "+37455195265",
		mode: "tel",
	},
	{
		id: 4,
		number: "info@realtime.am",
		lnumber: "info@realtime.am",
		mode: "mailto",
	},
	{
		id: 5,
		number: "hr@realtime.am",
		lnumber: "hr@realtime.am",
		mode: "mailto",
	},
];
